export default function CurrencyBuySellItemLoading(props) {
	return (
		<div className="w-100 px-3 py-2 d-flex flex-row" onClick={e=>props.selectHandler("0", "", "", null, "1", null)}>
			<div className="sell-select-currency-icon-box">
				<div className="w-100 h-100 position-relative">
					<div className="card-loading-animation" style={{width: "40px", height: "40px"}}></div>
				</div>
			</div>
			<div className="d-flex flex-row align-items-center justify-content-between" style={{width: "calc(100% - 40px)"}}>
				<div className="px-3">
					<div className="d-flex w-100 h-100 position-relative">
						<div className="card-loading-animation" style={{width: "70px", height: "25px"}}></div>
					</div>
				</div>
				<div className="sub-text-size">
					<div className="d-flex w-100 h-100 position-relative">
						<div className="card-loading-animation" style={{width: "40px", height: "25px"}}></div>
					</div>
				</div>
			</div>
		</div>
	)
}

import Image from "next/image"
import {useRouter} from "next/router"
import {useEffect, useState} from "react"
import {BadRequestErorToMessage, FormatPrice, ImageLoader, IsExist, IsLogin, LocalStorageGetter, LogError, MakePreOrder, NormalizedFormatedPrice, ShowToast, UserPanelServerUrl} from "../../config"
import {Registerlogin} from "../../components/login"

const PreOrderFees = function (getItemsUrl, postData, setDataItems, setLoadingFees, router) {
	setLoadingFees(true)
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST', 
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		body: JSONdata,
	}
	fetch(getItemsUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res.json()
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
			})
		}
		throw res
	})
	.then((resData) => {
		setDataItems(resData)
		setLoadingFees(false)
	})
	.catch((error) => {
		setLoadingFees(false)
		LogError(error,router)
	})
}

export default function BuyInvoice(props) {

	const router = useRouter()
	const[fees, setFees] = useState(null)
	const[loadingFees, setLoadingFees] = useState(false)
	const[blockchainID, setBlockchainID] = useState(0)

	const preOrderLoadingButtonText = "در حال ثبت سفارش"

	function resetPreOrder(){
		setFees(null)
		setLoadingFees(false)
		setBlockchainID(0)
	}

	function handleSetBlockchain(id){
		if(loadingFees == false){
			resetPreOrder()
			setBlockchainID(id)
			const postData = {
				coinID: props.selectedBuyCurrencyID,
				blockchainID: id,
				coinCount: Number(NormalizedFormatedPrice(props.userInputBuyCurrencyPriceIrtUnit.toString())),
			}
			const submitUrl = (UserPanelServerUrl + "/BuySell/BuyPreOrderFees")
			PreOrderFees(submitUrl, postData, setFees, setLoadingFees, router)
		}
	}

	function handleOrder(){
		if(IsLogin()){
			if(blockchainID == 0){
				ShowToast("warning", "برای ثبت سفارش لطفا شبکه ارز مورد نظر را انتخاب کنید", 8000, true, null)
			}else{
				if(document.getElementById("buy-pre-order-button").innerText == orderButtonText){
					const postData = {
						isBuy: true,
						coinID: props.selectedBuyCurrencyID,
						blockchainID: blockchainID,
						coinCount: NormalizedFormatedPrice(props.userInputBuyCurrencyPriceIrtUnit),
					}
					const submitUrl = (UserPanelServerUrl + "/BuySell/MakePreOrder")
					const redirectUrlBase = "/buy/accept-rules/"
					MakePreOrder(LocalStorageGetter("token"), submitUrl, redirectUrlBase, postData, router, orderButtonText, preOrderLoadingButtonText, "buy-pre-order-button")
				}
			}
		}else{
			props.setShowBuyInvoice(false)
			Registerlogin()
		}
	}

	const[orderButtonText, setOrderButtonText] = useState("")
	useEffect(()=>{
		setOrderButtonText(IsLogin() ? "ثبت سفارش" : "ورود به حساب کاربری")
	}, [orderButtonText])

	useEffect(()=>{
		resetPreOrder()
	}, [props.showBuyInvoice])

	return(
		<div className={(props.showBuyInvoice ? "d-flex " : "d-none ") + "justify-content-center align-items-center w-100"} style={{position:"fixed", top:"0", zIndex:"30", minHeight:"100%",backgroundColor:"var(--primary-50)"}}>
			<div className="invoice-layer d-flex justify-content-center align-items-center" style={{backgroundColor:"var(--background-color)", borderRadius:"var(--border-radius-s)"}}>
				<div className="w-100 p-4" style={{maxHeight:"90vh", overflow:"auto"}}>
					<div className="d-flex flex-column justify-content-center align-items-center py-3">
						<div className="w-100 d-flex flex-column justify-content-center align-items-center">
							<div className="position-relative" style={{width:"60px", height:"60px", marginTop:"-30px"}}>
								<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={props.selectedBuyCurrencyIcon} alt=""/>
							</div>
							<div className="d-flex align-items-center w-100 my-2" style={{justifyContent:"center"}}>
								<span>پیش فاکتور خرید</span>
							</div>
							<div className="d-flex align-items-center w-100" style={{justifyContent:"space-between"}}>
								<span>تعداد واحد خرید<span className="px-1">:</span></span><span className="text-center">{props.userInputBuyCurrencyPriceIrtUnit} {props.selectedBuyCurrencyNameFa}</span>
							</div>
							<span className="w-100 my-2" style={{borderBottom:"1px solid var(--invoice-grey)"}}></span>
							<div className="d-flex align-items-center w-100" style={{justifyContent:"space-between"}}>
								<span>مبلغ خرید<span className="px-1">:</span></span><span className="text-center">{props.userInputBuyCurrencyPriceIrt} تومان</span>
							</div>
							<span className="w-100 my-2" style={{borderBottom:"1px solid var(--invoice-grey)"}}></span>
							<div className="d-flex flex-column w-100">
								<div className="d-flex align-items-center mb-1">
									<span>شبکه ارز را انتخاب کنید<span className="px-1">:</span></span>
								</div>
								<div className="d-flex align-items-center w-100" style={{justifyContent:"space-between"}}>
									<div className="d-flex justify-content-center align-items-center w-100" style={{flexWrap:"wrap"}}>
										{
											props.networks == null ? "" :
											props.networks.map(function(item, index){
												return(<div key={index} onClick={e=>handleSetBlockchain(item.blockchain.blockchainID)} className={(blockchainID == item.blockchain.blockchainID ? "selected-invoice-network-button" : "invoice-network-button") + " text-center mx-2 my-1 px-3 py-2 c-transition"} style={{fontFamily:"Arial"}}>{item.blockchain.symbol}</div>)
											})
										}
									</div>
								</div>
							</div>
							<span className="w-100 my-2" style={{borderBottom:"1px solid var(--invoice-grey)"}}></span>
							<div className="w-100" style={{backgroundColor:"var(--grey-light)"}}>
								<div className="d-flex align-items-center w-100 px-3 py-2" style={{justifyContent:"space-between"}}>
									<span>کارمزد شبکه<span className="px-1">:</span></span>
									<div className="d-flex text-center">{
										loadingFees ?
										<div className="very-small-primary-lds-dual-ring mx-2"></div>
										:
										IsExist(fees) ? FormatPrice(fees.networkFeeIrt) : "-"
									} تومان</div>
								</div>
								<div className="d-flex align-items-center w-100 px-3 py-2" style={{justifyContent:"space-between"}}>
									<span>کارمزد تبدیل<span className="px-1">:</span></span>
									<div className="d-flex text-center">{
										loadingFees ?
										<div className="very-small-primary-lds-dual-ring mx-2"></div>
										:
										IsExist(fees) ? FormatPrice(fees.convertFeeIrt) : "-"
									} تومان</div>
								</div>
							</div>
							<span className="w-100 my-2" style={{borderBottom:"1px solid var(--invoice-grey)"}}></span>
							<div className="d-flex align-items-center w-100 px-3 py-3" style={{justifyContent:"space-between", backgroundColor:"var(--green-20)"}}>
								<span>مبلغ قابل پرداخت<span className="px-1">:</span></span>
								<span className="text-center">
									{
										IsExist(fees) ?
										FormatPrice(
											Number(fees.networkFeeIrt) +
											Number(fees.convertFeeIrt) +
											Number(NormalizedFormatedPrice(props.userInputBuyCurrencyPriceIrt.toString()))
										) + " تومان"
										:
										"شبکه ارز را انتخاب کنید"
									}
								</span>
							</div>
						</div>
					</div>
					<div className="row mt-2">
						<div className="col-6">
							<div onClick={e=>props.setShowBuyInvoice(false)}>
								<div className="w-100">
									<div className="danger-button-layer panel-button-layer c-transition">
										<span>لغو سفارش</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div>
								<div className="w-100">
									<div onClick={e=>handleOrder()} className="bs-box-button-layer c-transition" data-type={blockchainID == 0 ? "" : "enable"}>
										<span id="buy-pre-order-button">{orderButtonText}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

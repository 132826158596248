import {useEffect , useState} from 'react'
import {BadRequestErorToMessage, buySellNumbersDecimalSectionCharacterLengthLimit, CoinsServerIconsFolderUrl, CoinsSocketUrl, CryptoCurrencyPriceUpdates, formatNumberInputValue, FormatPrice, ImageLoader, ImagesVersion , IsExist, LogError, NormalizedFormatedPrice, NormalizeFormInputValue, RoundPrice, UserPanelServerUrl} from '../../config'
import $ from 'jquery' //jquery@3.5.1
import Image from 'next/image'
import {useRouter} from 'next/router'
import CurrencyBuyItem from './currency-buy-item'
import CurrencySellItem from './currency-sell-item'
import CurrencyBuySellItemLoading from './currency-buy-sell-item-loading'
import SellInvoice from './sell-invoice'
import BuyInvoice from './buy-invoice'

const orderBuyButtonText = "ثبت سفارش خرید"
const orderBuyLoadingButtonText = "در حال ثبت سفارش"

const orderSellButtonText = "ثبت سفارش فروش"
const orderSellLoadingButtonText = "در حال ثبت سفارش"

const SelectedBuySellCryptoCurrencyUpdates = function (json, buyCoinId, buyCoinPriceIrtUnit, userInputBuyCoinPriceIrtUnit, sellCoinId, sellCoinPriceIrtUnit, userInputSellCoinPriceIrtUnit) {
	JSON.parse(json).map(function(item, index){
		if(item.coinID == buyCoinId){
			let coinPrice = (item.buyPriceIrt / buyCoinPriceIrtUnit)
			let FinalPrice = FormatPrice(RoundPrice(coinPrice * Number(NormalizedFormatedPrice(userInputBuyCoinPriceIrtUnit))))
			$("#buy-cryptocurency-price").val(FinalPrice)
		}
		if(item.coinID == sellCoinId){
			let coinPrice = (item.sellPriceIrt / sellCoinPriceIrtUnit)
			let FinalPrice = FormatPrice(RoundPrice(coinPrice * Number(NormalizedFormatedPrice(userInputSellCoinPriceIrtUnit))))
			$("#sell-cryptocurency-price").val(FinalPrice)
		}
	})
}

const CheckBuyLimitsAndGetBlockchainCoins = function (getItemsUrl, postData, setDataItems, setShowBuyInvoice, router, coinName) {
	document.getElementById("buy-order-button").innerText = orderBuyLoadingButtonText
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST', 
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		body: JSONdata,
	}
	fetch(getItemsUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res.json()
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,coinName,result,router)
			})
		}
		throw res
	})
	.then((resData) => {
		setDataItems(resData)
		setShowBuyInvoice(true)
		document.getElementById("buy-order-button").innerText = orderBuyButtonText
	})
	.catch((error) => {
		document.getElementById("buy-order-button").innerText = orderBuyButtonText
		LogError(error,router)
	})
}

const CheckSellLimitsAndGetBlockchainCoins = function (getItemsUrl, postData, setDataItems, setShowSellInvoice, router, coinName) {
	document.getElementById("sell-order-button").innerText = orderSellLoadingButtonText
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST', 
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		body: JSONdata,
	}
	fetch(getItemsUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res.json()
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,coinName,result,router)
			})
		}
		throw res
	})
	.then((resData) => {
		setDataItems(resData)
		setShowSellInvoice(true)
		document.getElementById("sell-order-button").innerText = orderSellButtonText
	})
	.catch((error) => {
		document.getElementById("sell-order-button").innerText = orderSellButtonText
		LogError(error,router)
	})
}

export default function BuySell(props) {

	const router = useRouter()
	const[socket, setSocket] = useState(null)
	const[networks, setNetworks] = useState(null)
	const[showBuyInvoice, setShowBuyInvoice] = useState(false)
	const[showSellInvoice, setShowSellInvoice] = useState(false)
	const[socketData, setSocketData] = useState(null)

	const[selectedBuyCurrencyID, setSelectedBuyCurrencyID] = useState(0)
	const[selectedBuyCurrencyNameFa, setSelectedBuyCurrencyNameFa] = useState("")
	const[selectedBuyCurrencySymbol, setSelectedBuyCurrencySymbol] = useState("")
	const[selectedBuyCurrencyIcon, setSelectedBuyCurrencyIcon] = useState(null)
	const[selectedBuyCurrencyPriceIrtUnit, setSelectedBuyCurrencyPriceIrtUnit] = useState(1)
	const[selectedBuyCurrencyPriceIrt, setSelectedBuyCurrencyPriceIrt] = useState("")
	const[userInputBuyCurrencyPriceIrtUnit, setUserInputBuyCurrencyPriceIrtUnit] = useState("")
	const[userInputBuyCurrencyPriceIrt, setUserInputBuyCurrencyPriceIrt] = useState("")

	const[selectedSellCurrencyID, setSelectedSellCurrencyID] = useState(0)
	const[selectedSellCurrencyNameFa, setSelectedSellCurrencyNameFa] = useState("")
	const[selectedSellCurrencySymbol, setSelectedSellCurrencySymbol] = useState("")
	const[selectedSellCurrencyIcon, setSelectedSellCurrencyIcon] = useState(null)
	const[selectedSellCurrencyPriceIrtUnit, setSelectedSellCurrencyPriceIrtUnit] = useState(1)
	const[selectedSellCurrencyPriceIrt, setSelectedSellCurrencyPriceIrt] = useState("")
	const[userInputSellCurrencyPriceIrtUnit, setUserInputSellCurrencyPriceIrtUnit] = useState("")
	const[userInputSellCurrencyPriceIrt, setUserInputSellCurrencyPriceIrt] = useState("")

	function buyCoinsLayer(id, name, symbol, icon, PriceIrtUnit, priceIrt){
		$("#buy-select-search-currency-layer").addClass("d-none")
		setSelectedBuyCurrencyID(id)
		setSelectedBuyCurrencyNameFa(name)
		setSelectedBuyCurrencySymbol(symbol)
		setSelectedBuyCurrencyIcon(icon)
		setSelectedBuyCurrencyPriceIrtUnit(PriceIrtUnit)
		setSelectedBuyCurrencyPriceIrt(FormatPrice(priceIrt))

		setUserInputBuyCurrencyPriceIrtUnit(PriceIrtUnit)
		setUserInputBuyCurrencyPriceIrt(FormatPrice(priceIrt) == "NaN" ? "" : FormatPrice(priceIrt))

		$("#buy-search-currency-input").val("")
		$('.buy-coins-layer').each(function(i, obj) {
			$(this).removeClass("d-none")
		})
	}

	function sellCoinsLayer(id, name, symbol, icon, PriceIrtUnit, priceIrt){
		$("#sell-select-search-currency-layer").addClass("d-none")
		setSelectedSellCurrencyID(id)
		setSelectedSellCurrencyNameFa(name)
		setSelectedSellCurrencySymbol(symbol)
		setSelectedSellCurrencyIcon(icon)
		setSelectedSellCurrencyPriceIrtUnit(PriceIrtUnit)
		setSelectedSellCurrencyPriceIrt(FormatPrice(priceIrt))

		setUserInputSellCurrencyPriceIrtUnit(PriceIrtUnit)
		setUserInputSellCurrencyPriceIrt(FormatPrice(priceIrt) == "NaN" ? "" : FormatPrice(priceIrt))

		$("#sell-search-currency-input").val("")
		$('.sell-coins-layer').each(function(i, obj) {
			$(this).removeClass("d-none")
		})
	}

	function BuyCryptocurencyUnitInputChange(value){
		value = formatNumberInputValue(value)
		if(IsExist(value)){
			setUserInputBuyCurrencyPriceIrtUnit(value)

			let coinPrice = (Number(NormalizedFormatedPrice(selectedBuyCurrencyPriceIrt)) / selectedBuyCurrencyPriceIrtUnit)
			let FinalPrice = FormatPrice(RoundPrice(coinPrice * NormalizedFormatedPrice(value)))
			setUserInputBuyCurrencyPriceIrt(FinalPrice == "NaN" ? "" : FinalPrice)
		}
	}

	function BuyCryptocurencyPriceInputChange(value){
		value = formatNumberInputValue(value)
		if(IsExist(value) && value != "NaN"){
			setUserInputBuyCurrencyPriceIrt(value)

			let coinUnit = (Number(NormalizedFormatedPrice(value)) / Number(NormalizedFormatedPrice(selectedBuyCurrencyPriceIrt)) * Number(selectedBuyCurrencyPriceIrtUnit))
			coinUnit = Number(coinUnit).toLocaleString(undefined, { minimumFractionDigits: 0,maximumFractionDigits: buySellNumbersDecimalSectionCharacterLengthLimit})
			setUserInputBuyCurrencyPriceIrtUnit(coinUnit == "NaN" ? "" : coinUnit)
		}
	}

	function SellCryptocurencyUnitInputChange(value){
		value = formatNumberInputValue(value)
		if(IsExist(value)){
			setUserInputSellCurrencyPriceIrtUnit(value)

			let coinPrice = (Number(NormalizedFormatedPrice(selectedSellCurrencyPriceIrt)) / selectedSellCurrencyPriceIrtUnit)
			let FinalPrice = FormatPrice(RoundPrice(coinPrice * NormalizedFormatedPrice(value)))
			setUserInputSellCurrencyPriceIrt(FinalPrice == "NaN" ? "" : FinalPrice)
		}
	}

	function SellCryptocurencyPriceInputChange(value){
		value = formatNumberInputValue(value)
		if(IsExist(value) && value != "NaN"){
			setUserInputSellCurrencyPriceIrt(value)

			let coinUnit = (Number(NormalizedFormatedPrice(value)) / Number(NormalizedFormatedPrice(selectedSellCurrencyPriceIrt)) * Number(selectedSellCurrencyPriceIrtUnit))
			coinUnit = Number(coinUnit).toLocaleString(undefined, { minimumFractionDigits: 0,maximumFractionDigits: buySellNumbersDecimalSectionCharacterLengthLimit})
			setUserInputSellCurrencyPriceIrtUnit(coinUnit == "NaN" ? "" : coinUnit)
		}
	}

	function buySelectCurrencyBox(){
		$("#buy-select-search-currency-layer").removeClass("d-none")
	}

	function sellSelectCurrencyBox(){
		$("#sell-select-search-currency-layer").removeClass("d-none")
	}

	function buySearchCurrencyInput(e){
		let searchval = $(e).val().toLowerCase()
		$('.buy-coins-layer').each(function(i, obj) {
			let nameFa = $(this).data("namefa").toLowerCase()
			let nameEn = $(this).data("nameen").toLowerCase()
			let symbol = $(this).data("symbol").toLowerCase()
			if(nameFa.toString().includes(searchval) || nameEn.toString().includes(searchval) || symbol.toString().toLowerCase().includes(searchval)){
				$(this).removeClass("d-none")
			}else{
				$(this).addClass("d-none")
			}
		})
	}

	function sellSearchCurrencyInput(e){
		let searchval = $(e).val().toLowerCase()
		$('.sell-coins-layer').each(function(i, obj) {
			let nameFa = $(this).data("namefa").toLowerCase()
			let nameEn = $(this).data("nameen").toLowerCase()
			let symbol = $(this).data("symbol").toLowerCase()
			if(nameFa.toString().includes(searchval) || nameEn.toString().includes(searchval) || symbol.toString().toLowerCase().includes(searchval)){
				$(this).removeClass("d-none")
			}else{
				$(this).addClass("d-none")
			}
		})
	}

	function handleBuyOrder(){
		if(document.getElementById("buy-order-button").innerText == orderBuyButtonText){
			const postData = {
				coinID: NormalizeFormInputValue(selectedBuyCurrencyID),
				coinCount: Number(NormalizedFormatedPrice(userInputBuyCurrencyPriceIrtUnit.toString())),
			}
			const submitUrl = (UserPanelServerUrl + "/BuySell/CheckBuyLimitsAndGetBlockchainCoins")
			CheckBuyLimitsAndGetBlockchainCoins(submitUrl, postData, setNetworks, setShowBuyInvoice, router, selectedBuyCurrencyNameFa)
		}
	}

	function handleSellOrder(){
		if(document.getElementById("sell-order-button").innerText == orderSellButtonText){
			const postData = {
				coinID: NormalizeFormInputValue(selectedSellCurrencyID),
				coinCount: Number(NormalizedFormatedPrice(userInputSellCurrencyPriceIrtUnit.toString())),
			}
			const submitUrl = (UserPanelServerUrl + "/BuySell/CheckSellLimitsAndGetBlockchainCoins")
			CheckSellLimitsAndGetBlockchainCoins(submitUrl, postData, setNetworks, setShowSellInvoice, router, selectedSellCurrencyNameFa)
		}
	}

	useEffect(() => {
		if(IsExist(props.dataItems)){
			setSelectedBuyCurrencyID(props.dataItems[0].coinID)
			setSelectedBuyCurrencyNameFa(props.dataItems[0].nameFa)
			setSelectedBuyCurrencySymbol(props.dataItems[0].symbol)
			setSelectedBuyCurrencyIcon(CoinsServerIconsFolderUrl + "/coins/" + props.dataItems[0].symbol + ".png?v=" + ImagesVersion)
			setSelectedBuyCurrencyPriceIrtUnit(props.dataItems[0].priceIrtUnit)
			setSelectedBuyCurrencyPriceIrt(props.dataItems[0].buyPriceIrt)
			setUserInputBuyCurrencyPriceIrtUnit(props.dataItems[0].priceIrtUnit)
			setUserInputBuyCurrencyPriceIrt(FormatPrice(props.dataItems[0].buyPriceIrt))

			setSelectedSellCurrencyID(props.dataItems[0].coinID)
			setSelectedSellCurrencyNameFa(props.dataItems[0].nameFa)
			setSelectedSellCurrencySymbol(props.dataItems[0].symbol)
			setSelectedSellCurrencyIcon(CoinsServerIconsFolderUrl + "/coins/" + props.dataItems[0].symbol + ".png?v=" + ImagesVersion)
			setSelectedSellCurrencyPriceIrtUnit(props.dataItems[0].priceIrtUnit)
			setSelectedSellCurrencyPriceIrt(props.dataItems[0].sellPriceIrt)
			setUserInputSellCurrencyPriceIrtUnit(props.dataItems[0].priceIrtUnit)
			setUserInputSellCurrencyPriceIrt(FormatPrice(props.dataItems[0].sellPriceIrt))
		}
	}, [props.dataItems])

	useEffect(()=>{
		if(socket == null){
			setSocket(new WebSocket(CoinsSocketUrl))
		}else{
			socket.onmessage = function(e) {
				setSocketData(e.data)
			}
		}

		if(IsExist(socketData)){
			CryptoCurrencyPriceUpdates(socketData)
			SelectedBuySellCryptoCurrencyUpdates(socketData, 
												selectedBuyCurrencyID, selectedBuyCurrencyPriceIrtUnit, userInputBuyCurrencyPriceIrtUnit,
												selectedSellCurrencyID, selectedSellCurrencyPriceIrtUnit, userInputSellCurrencyPriceIrtUnit)
		}
	}, [socket, socketData,
		selectedBuyCurrencyID,
		selectedBuyCurrencyPriceIrtUnit,
		selectedSellCurrencyID,
		selectedSellCurrencyPriceIrtUnit,
		userInputBuyCurrencyPriceIrtUnit,
		userInputSellCurrencyPriceIrtUnit])

	return (
		<>
			<div className="col-12 col-sm-10 col-md-6 col-lg-6 col-xl-5 col-xxl-4 mb-5 px-3 px-sm-5 px-md-4">
				<div className="sell-buy-form w-100 d-flex justify-content-center flex-column py-4">
					<div className="d-flex w-100 justify-content-between">
						<div className="d-flex justify-content-center align-items-center c-transition buy-sell-forms-title-box">
							<span style={{color: "var(--green)"}}>درخواست خرید</span>
						</div>
					</div>
					<div className="py-2"></div>
					<div className="w-100 px-4">
						<div onClick={e=>buySelectCurrencyBox()} id="buy-select-currency-box" className="w-100 d-flex align-items-center">
							<div className="w-100 d-flex justify-content-center align-items-center flex-row">
								<div className="p-1 select-cryptocurency-icon-box">
									<div className="w-100 h-100 position-relative">
										{
											IsExist(selectedBuyCurrencyIcon) ?
											<Image loader={ImageLoader} id="buy-select-cryptocurency-icon" layout="fill" objectFit="contain" loading="lazy" src={selectedBuyCurrencyIcon} alt={selectedBuyCurrencyNameFa == "" ? "" : "آیکون " + selectedBuyCurrencyNameFa}/>
											:
											<div className="w-100 h-100 position-relative">
												<div className="card-loading-animation" style={{width: "30px", height: "30px"}}></div>
											</div>
										}
									</div>
								</div>
								<div id="buy-select-cryptocurency-name" className="d-flex justify-content-center align-items-center px-1">{selectedBuyCurrencyNameFa}</div>
								<div className="d-flex justify-content-center align-items-center pt-1 sub-text-size">
									{
										selectedBuyCurrencySymbol != "" ?
										<span id="buy-select-cryptocurency-symbol">{"(" + selectedBuyCurrencySymbol + ")"}</span>
										:
										<div className="w-100 h-100 position-relative">
											<div className="card-loading-animation" style={{width: "60px", height: "20px"}}></div>
										</div>
									}
								</div>
							</div>
							<div className="cryptocurency-info-select-arrow d-block p-2">
								<svg className="d-block w-100 h-100" viewBox="0 0 16 16"><path fill="CurrentColor" fillRule="nonzero" transform="rotate(0)" d="M8 10.7c-.18 0-.36-.07-.496-.207L3.205 6.194a.7.7 0 01.988-.99L8 9.008l3.802-3.801A.703.703 0 0113 5.7a.698.698 0 01-.205.494l-4.302 4.302A.711.711 0 018 10.7z"></path></svg>
							</div>
						</div>
					</div>
					<div className="py-2"></div>
					<div className="w-100 px-4">
						<div className="w-100 d-flex align-items-center justify-content-center sell-buy-inputs-box">
							<input id="buy-cryptocurency-unit" autoComplete="off" type="text" placeholder="مقدار را وارد کنید" className="w-100 h-100 text-center sell-buy-inputs" value={userInputBuyCurrencyPriceIrtUnit} onChange={e=>BuyCryptocurencyUnitInputChange(e.target.value)} style={{direction:"ltr"}}/>
							<span className="sell-buy-inputs-label">واحد</span>
						</div>
					</div>
					<div className="py-2"></div>
					<div className="w-100 px-4">
						<div className="w-100 d-flex align-items-center justify-content-center sell-buy-inputs-box">
							<input id="buy-cryptocurency-price" autoComplete="off" type="text" placeholder="مبلغ را وارد کنید" className="w-100 h-100 text-center fsdfsd sell-buy-inputs" value={userInputBuyCurrencyPriceIrt} onChange={e=>BuyCryptocurencyPriceInputChange(e.target.value)} style={{direction:"ltr"}}/>
							<span className="sell-buy-inputs-label">تومان</span>
						</div>
					</div>
					<div className="py-2"></div>
					<div className="w-100 px-4">
						<div onClick={e=>handleBuyOrder()} className="w-100 d-flex align-items-center justify-content-center c-transition buy-button-box">
							<div id="buy-order-button" className="text-center text-white fw-bold">{orderBuyButtonText}</div>
						</div>
					</div>
					<div id="buy-select-search-currency-layer" className="d-none w-100 h-100">
						<div className="w-100 sell-buy-search-currency-layer-parent">
							<div className="d-flex w-100 h-100 d-flex align-items-center sell-buy-search-currency-layer">
								<div className="d-flex w-100 h-100 d-flex align-items-center sell-buy-search-currency-input-box">
									<input onInput={e=>buySearchCurrencyInput(e.target)} id="buy-search-currency-input" type="text" placeholder="جستجوی ارز دیجیتال" className="w-100 h-100 text-center" style={{direction:"ltr"}}/>
								</div>
								<div className="d-block p-3 sell-buy-search-currency-icon">
									<svg className="d-block w-100 h-100" viewBox="0 0 512 512"><path fill="CurrentColor" d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/></svg>
								</div>
							</div>
						</div>
						<div className="w-100 sell-buy-search-currency-border"></div>
						<div className="w-100 sell-buy-coins-layer">
							{
								IsExist(props.dataItems) ?
								props.dataItems.map(function(item, index){
									return (
										<CurrencyBuyItem key={index} item={item} index={index} selectHandler={buyCoinsLayer}/>
									)
								})
								:
								props.dataItemsLoadingCount.map(function(item, index){
									return (
										<CurrencyBuySellItemLoading key={index} selectHandler={buyCoinsLayer}/>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>

			<div className="col-12 col-sm-10 col-md-6 col-lg-6 col-xl-5 col-xxl-4 mb-5 px-3 px-sm-5 px-md-4">
				<div className="sell-buy-form w-100 d-flex justify-content-center flex-column py-4">
					<div className="d-flex w-100 justify-content-between">
						<div className="d-flex justify-content-center align-items-center c-transition buy-sell-forms-title-box">
							<span style={{color: "var(--red)"}}>درخواست فروش</span>
						</div>
					</div>
					<div className="py-2"></div>
					<div className="w-100 px-4">
						<div onClick={e=>sellSelectCurrencyBox()} id="sell-select-currency-box" className="w-100 d-flex align-items-center">
							<div className="w-100 d-flex justify-content-center align-items-center flex-row">
								<div className="p-1 select-cryptocurency-icon-box">
									<div className="w-100 h-100 position-relative">
										{
											IsExist(selectedSellCurrencyIcon) ?
											<Image loader={ImageLoader} id="sell-select-cryptocurency-icon" layout="fill" objectFit="contain" loading="lazy" src={selectedSellCurrencyIcon} alt={selectedSellCurrencyNameFa == "" ? "" : "آیکون " + selectedSellCurrencyNameFa}/>
											:
											<div className="w-100 h-100 position-relative">
												<div className="card-loading-animation" style={{width: "30px", height: "30px"}}></div>
											</div>
										}
									</div>
								</div>
								<div id="sell-select-cryptocurency-name" className="d-flex justify-content-center align-items-center px-1">{selectedSellCurrencyNameFa}</div>
								<div className="d-flex justify-content-center align-items-center pt-1 sub-text-size">
									{
										selectedSellCurrencySymbol != "" ?
										<span id="sell-select-cryptocurency-symbol">{"(" + selectedSellCurrencySymbol + ")"}</span>
										:
										<div className="w-100 h-100 position-relative">
											<div className="card-loading-animation" style={{width: "60px", height: "20px"}}></div>
										</div>
									}
								</div>
							</div>
							<div className="cryptocurency-info-select-arrow d-block p-2">
								<svg className="d-block w-100 h-100" viewBox="0 0 16 16"><path fill="CurrentColor" fillRule="nonzero" transform="rotate(0)" d="M8 10.7c-.18 0-.36-.07-.496-.207L3.205 6.194a.7.7 0 01.988-.99L8 9.008l3.802-3.801A.703.703 0 0113 5.7a.698.698 0 01-.205.494l-4.302 4.302A.711.711 0 018 10.7z"></path></svg>
							</div>
						</div>
					</div>
					<div className="py-2"></div>
					<div className="w-100 px-4">
						<div className="w-100 d-flex align-items-center justify-content-center sell-buy-inputs-box">
							<input id="sell-cryptocurency-unit" autoComplete="off" type="text" placeholder="مقدار را وارد کنید" className="w-100 h-100 text-center sell-buy-inputs" value={userInputSellCurrencyPriceIrtUnit} onChange={e=>SellCryptocurencyUnitInputChange(e.target.value)} style={{direction:"ltr"}}/>
							<span className="sell-buy-inputs-label">واحد</span>
						</div>
					</div>
					<div className="py-2"></div>
					<div className="w-100 px-4">
						<div className="w-100 d-flex align-items-center justify-content-center sell-buy-inputs-box">
							<input id="sell-cryptocurency-price" autoComplete="off" type="text" placeholder="مبلغ را وارد کنید" className="w-100 h-100 text-center fsdfsd sell-buy-inputs" value={userInputSellCurrencyPriceIrt} onChange={e=>SellCryptocurencyPriceInputChange(e.target.value)} style={{direction:"ltr"}}/>
							<span className="sell-buy-inputs-label">تومان</span>
						</div>
					</div>
					<div className="py-2"></div>
					<div className="w-100 px-4">
						<div onClick={e=>handleSellOrder()} className="w-100 d-flex align-items-center justify-content-center c-transition sell-button-box">
							<div id="sell-order-button" className="text-center text-white fw-bold">{orderSellButtonText}</div>
						</div>
					</div>
					<div id="sell-select-search-currency-layer" className="d-none w-100 h-100">
						<div className="w-100 sell-buy-search-currency-layer-parent">
							<div className="d-flex w-100 h-100 d-flex align-items-center sell-buy-search-currency-layer">
								<div className="d-flex w-100 h-100 d-flex align-items-center sell-buy-search-currency-input-box">
									<input onInput={e=>sellSearchCurrencyInput(e.target)} id="sell-search-currency-input" type="text" placeholder="جستجوی ارز دیجیتال" className="w-100 h-100 text-center" style={{direction:"ltr"}}/>
								</div>
								<div className="d-block p-3 sell-buy-search-currency-icon">
									<svg className="d-block w-100 h-100" viewBox="0 0 512 512"><path fill="CurrentColor" d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/></svg>
								</div>
							</div>
						</div>
						<div className="w-100 sell-buy-search-currency-border"></div>
						<div className="w-100 sell-buy-coins-layer">
							{
								IsExist(props.dataItems) ?
								props.dataItems.map(function(item, index){
									return (
										<CurrencySellItem key={index} item={item} index={index} selectHandler={sellCoinsLayer}/>
									)
								})
								:
								props.dataItemsLoadingCount.map(function(item, index){
									return (
										<CurrencyBuySellItemLoading key={index} selectHandler={sellCoinsLayer}/>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>

			<BuyInvoice
				showBuyInvoice={showBuyInvoice}
				setShowBuyInvoice={setShowBuyInvoice}
				networks={networks}
				selectedBuyCurrencyID={selectedBuyCurrencyID}
				selectedBuyCurrencyNameFa={selectedBuyCurrencyNameFa}
				selectedBuyCurrencyIcon={IsExist(selectedBuyCurrencyIcon) ? selectedBuyCurrencyIcon : ""}
				userInputBuyCurrencyPriceIrtUnit={userInputBuyCurrencyPriceIrtUnit}
				userInputBuyCurrencyPriceIrt={userInputBuyCurrencyPriceIrt}/>

			<SellInvoice
				showSellInvoice={showSellInvoice}
				setShowSellInvoice={setShowSellInvoice}
				networks={networks}
				selectedSellCurrencyID={selectedSellCurrencyID}
				selectedSellCurrencyNameFa={selectedSellCurrencyNameFa}
				selectedSellCurrencyIcon={IsExist(selectedSellCurrencyIcon) ? selectedSellCurrencyIcon : ""}
				userInputSellCurrencyPriceIrtUnit={userInputSellCurrencyPriceIrtUnit}
				userInputSellCurrencyPriceIrt={userInputSellCurrencyPriceIrt}/>

		</>
	)
}

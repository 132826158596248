import Image from "next/image"
import {CoinsServerIconsFolderUrl, ImageLoader, ImagesVersion} from "../../config"

export default function CurrencySellItem(props) {

	const id = props.item.coinID
	const nameFa = props.item.nameFa
	const nameEn = props.item.nameEn
	const symbol = props.item.symbol
	const icon = CoinsServerIconsFolderUrl + "/coins/" + props.item.symbol + ".png?v=" + ImagesVersion
	const priceIrtUnit = props.item.priceIrtUnit
	const sellPriceIrt = props.item.sellPriceIrt

	return (
		<div className="w-100 px-3 py-2 d-flex flex-row sell-coins-layer" onClick={e=>props.selectHandler(id, nameFa, symbol, icon, priceIrtUnit, sellPriceIrt)} data-id={id} data-icon={icon} data-namefa={nameFa} data-nameen={nameEn} data-symbol={symbol}>
			<div className="sell-select-currency-icon-box">
				<div className="w-100 h-100 position-relative">
					<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={icon} alt={"آیکون " + nameFa}/>
				</div>
			</div>
			<div className="d-flex flex-row align-items-center justify-content-between" style={{width: "calc(100% - 40px)"}}>
				<div className="px-3">{nameFa}</div>
				<div className="sub-text-size">{symbol}</div>
			</div>
		</div>
	)
}
